<template>
    <div>
        <div>
            <breadcrumb
                name="Branches"
                title="All Mansour Branches"
                :subtitle="item ? 'Edit' : 'Add'"
                @onTitleClicked="goBack"
            />
        </div>
        <div class="body">
            <h6 class="text-danger pa-1">
                Fields marked with (*) are mandatory
            </h6>

            <vs-row style="margin-left: 20px; margin-right: 20px">
                <vs-col vs-lg="6" vs-md="12">
                    <vs-input
                        label="Name English*"
                        v-model="form_data.name_en"
                        size="medium"
                        class="input"
                        danger-text="This field is mandatory"
                    />
                </vs-col>
                <vs-col vs-lg="6" vs-md="12">
                    <vs-input
                        label="Name Arabic*"
                        v-model="form_data.name_ar"
                        size="medium"
                        class="input"
                        danger-text="This field is mandatory"
                    />
                </vs-col>
            </vs-row>

            <vs-row style="margin-left: 20px; margin-right: 20px">
                <vs-col vs-lg="6" vs-md="12">
                    <vs-input
                        label="Area English*"
                        v-model="form_data.area_en"
                        size="medium"
                        class="input"
                        danger-text="This field is mandatory"
                    />
                </vs-col>
                <vs-col vs-lg="6" vs-md="12">
                    <vs-input
                        label="Area Arabic*"
                        v-model="form_data.area_ar"
                        size="medium"
                        class="input"
                        danger-text="This field is mandatory"
                    />
                </vs-col>
            </vs-row>

            <vs-row
                style="margin-left: 20px; margin-right: 20px; margin-top: 10px"
            >
                <vs-col vs-lg="6" vs-sm="12">
                    <div class="drop-down">
                        <label for="types">Select Branch Types</label>
                        <multiselect
                            id="types"
                            v-model="form_data.types"
                            style="height: 40px"
                            :options="getTypeOptions()"
                            :custom-label="getTypeName"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :multiple="true"
                        />
                    </div>
                </vs-col>

                <vs-col vs-lg="6" vs-sm="12">
                    <div class="drop-down">
                        <label for="serve"
                            >Select which car types this Branch serves?</label
                        >
                        <multiselect
                            id="serve"
                            v-model="form_data.serve"
                            style="height: 40px"
                            :options="getServesOptions()"
                            :custom-label="getServesName"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :multiple="true"
                        />
                    </div>
                </vs-col>
            </vs-row>

            <vs-row
                style="margin-left: 20px; margin-right: 20px; margin-top: 10px"
            >
                <vs-col vs-lg="6" vs-sm="12">
                    <div class="drop-down">
                        <label for="brands"
                            >Select which brands this Branch serves?</label
                        >
                        <multiselect
                            id="brands"
                            v-model="form_data.brands"
                            style="height: 40px"
                            :options="brandsOptions"
                            :custom-label="getBrandName"
                            :close-on-select="false"
                            :clear-on-select="false"
                            :multiple="true"
                        />
                    </div>
                </vs-col>

                <vs-col vs-lg="6" vs-md="12">
                    <vs-input
                        label="Phone Number*"
                        v-model="form_data.mobilenumber"
                        size="medium"
                        class="input"
                        type="number"
                        danger-text="This field is mandatory"
                    />
                </vs-col>
            </vs-row>

            <vs-row
                style="margin-left: 20px; margin-right: 20px; margin-top: 10px"
            >
                <vs-col vs-lg="6" vs-md="12">
                    <vs-input
                        label="Branch Latitude*"
                        v-model="form_data.location.lat"
                        size="medium"
                        class="input"
                        type="number"
                        danger-text="This field is mandatory"
                    />

                    <div v-if="this.errors_bag.lat" class="text-danger">
                        Invalid Latitude value
                    </div>
                </vs-col>
                <vs-col vs-lg="6" vs-md="12">
                    <vs-input
                        label="Branch Longitude*"
                        v-model="form_data.location.lng"
                        size="medium"
                        class="input"
                        type="number"
                        danger-text="This field is mandatory"
                    />

                    <div v-if="this.errors_bag.lng" class="text-danger">
                        Invalid Longitude value
                    </div>
                </vs-col>
            </vs-row>

            <vs-row>
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    class="pa-4"
                    vs-align="center"
                    vs-w="6"
                >
                    <text-area
                        label="Address English"
                        v-model="form_data.address_en"
                        rows="6"
                        :name="`address_en`"
                    />
                </vs-col>
                <vs-col
                    vs-type="flex"
                    vs-justify="center"
                    class="pa-4"
                    vs-align="center"
                    vs-w="6"
                >
                    <text-area
                        label="Address Arabic"
                        v-model="form_data.address_ar"
                        rows="6"
                        :name="`address_en`"
                    />
                </vs-col>
            </vs-row>
            <vs-row style="margin-left: 20px; margin-right: 20px">
              <vs-col vs-lg="6" vs-md="12">
                <vs-input
                  label="Sales Office Code*"
                  v-model="form_data.sales_office_code"
                  size="medium"
                  class="input"
                  danger-text="This field is mandatory"
                />
              </vs-col>
            </vs-row>
            <div class="p-4">
                <div class="p-4" style="border: 1px solid rgba(0,0,0,.2);border-radius: 5px">
                    <h3>Contacts</h3>
                    <vs-row style="margin-left: 20px; margin-right: 20px; margin-top: 10px">

                        <vs-col v-for="(num, k) in form_data.contacts" :key="k" vs-w="6">
                            <vs-row vs-s="flex-end" vs-align="flex-end">
                                <vs-col vs-w="10">
                                    <vs-input
                                        label="Contact Number*"
                                        v-model="form_data.contacts[k]"
                                        size="medium"
                                        class="input"
                                        type="number"
                                        danger-text="This field is mandatory"
                                    />
                                </vs-col>
                                <vs-col vs-w="2">
                                    <feather-icon
                                        icon="TrashIcon"
                                        svgClasses="w-6 h-6 hover:text-danger stroke-current"
                                        @click.stop="removeContactNumber(k)"
                                    />
                                </vs-col>
                            </vs-row>
                        </vs-col>
                    </vs-row>
                    <div class="flex flex-wrap-reverse items-center p-5">
                        <vs-button color="primary" type="border" icon-pack="feather" icon="icon-plus" @click="addContactNumber">
                        Add New Contact number
                        </vs-button>
                    </div>
                </div>

            </div>

            <div class="p-4">
                <div class="p-4" style="border: 1px solid rgba(0,0,0,.2);border-radius: 5px">
                    <h3>Working days</h3>

                    <div class="flex flex-wrap-reverse items-center p-5" v-if="form_data.work_days.length < getDays().length">
                        <vs-button color="primary" type="border" icon-pack="feather" icon="icon-plus" @click="add_default_work_days">
                        Add Work days
                        </vs-button>
                    </div>
                    <vs-row
                        style="margin-left: 20px; margin-right: 20px; margin-top: 10px"
                        v-for="(days, k) in form_data.work_days" :key="k">

                        <vs-col vs-lg="3" vs-md="12">
                            <div class="drop-down">
                                <label for="brands">Select week day</label>
                                <multiselect
                                    id="types"
                                    v-model="days.day"
                                    style="height: 40px"
                                    :options="getDays()"
                                    :multiple="false"
                                />
                            </div>
                        </vs-col>
                        <vs-col vs-lg="3" vs-md="12">
                            <vs-input
                                label="Working Hours From"
                                v-model="days.from"
                                size="medium"
                                class="input"
                                type="time"
                            />
                        </vs-col>
                        <vs-col vs-lg="3" vs-md="12">
                            <vs-input
                                label="Working Hours To"
                                v-model="days.to"
                                size="medium"
                                class="input"
                                type="time"
                            />
                        </vs-col>

                        <vs-col vs-lg="3">
                            <div class="switch">
                                <label>Active</label>
                                <vs-switch v-model="days.on" />
                            </div>
                        </vs-col>

                    </vs-row>
                </div>
                <!-- <div class="flex flex-wrap-reverse items-center">
                    <vs-button color="primary" type="border" icon-pack="feather" icon="icon-plus" @click="$emit('click')">
                    Add New
                    </vs-button>
                </div> -->
            </div>


            <br /><hr /><br />

            <vs-row
                style="margin-left: 20px; margin-right: 20px; margin-top: -40px;position: relative;"
            >
                <vs-col vs-lg="3" vs-md="12">
                    <div class="switch">
                        <label>Show On Mobile</label>
                        <vs-switch v-model="form_data.mobile_active" />
                    </div>
                </vs-col>

                <vs-col vs-lg="3" vs-md="12">
                    <div class="switch">
                        <label>Is Mansour Branch?</label>
                        <vs-switch v-model="form_data.mansour_flag" />
                    </div>
                </vs-col>

                <vs-col vs-lg="3" vs-md="12">
                    <div class="switch">
                        <label>Show In RSA</label>
                        <vs-switch v-model="form_data.show_on_rsa" />
                    </div>
                </vs-col>

              <vs-col vs-lg="3" vs-md="12">
                <div class="switch">
                  <label>Realtime Reservation</label>
                  <vs-switch v-model="form_data.realtime_reservation" />
                </div>
              </vs-col>
            </vs-row>

            <div class="optionBtns">
                <vs-button
                    icon-pack="feather"
                    icon="icon-save"
                    :disabled="!validBranch"
                    @click="confirmSaveBranch"
                    >Save
                </vs-button>
                <vs-button
                    type="border"
                    class="ml-5 mr-10"
                    color="primary"
                    @click="goBack"
                    >Cancel</vs-button
                >
            </div>
        </div>
    </div>
</template>

<script>
import ChooseModel from "../../../components/custom/choose-model";
import Breadcrumb from "../../../components/general/breadcrumb";
import TextArea from "../../../components/form/text-area";
import UploadFile from "../../../components/uploader/upload-file";
import Multiselect from "vue-multiselect";

export default {
    name: "add-branch",
    components: { TextArea, Breadcrumb, ChooseModel, UploadFile, Multiselect },
    props: {
        item: Object
    },
    data() {
        return {
            form_data: {
                name_en: "",
                name_ar: "",
                address_en: "",
                address_ar: "",
                area_en: "",
                area_ar: "",
                mobilenumber: "",
                mansour_flag: true,
                show_on_rsa: true,
                realtime_reservation: false,
                sales_office_code : "",
                working_hours_from: "",
                working_hours_to: "",
                mobile_active: true,
                location: {
                    lat: 0.0,
                    lng: 0.0,
                },
                work_days: this.get_default_work_days(),
                contacts: [''],
                types: [],
                serve: [],
                brands: []
            },
            // types: [],
            typeOptions: {1: "Showroom", 2: "Service Center", 3: "Spare Parts"},
            servesOptions: {0: "Passengers", 1: "Trucks"},
            // brands: [],
            brandsValues: [],
            brandsOptions: [],
            mainBrands: [],
            errors_bag: {}
        };
    },
    methods: {
        getDays() {
            return [
                "Saturday",
                "Sunday",
                "Monday",
                "Tuesday",
                "Wednesday",
                "Thursday",
                "Friday",
            ];
        },
        get_work_day(day = '') {
            return { day, from: "09:00", to: "18:00", on: true }
        },
        get_default_work_days() {
            let work_days = []
            this.getDays().forEach(day => {
                work_days.push(this.get_work_day(day))
            });
            return work_days;
        },
        add_default_work_days(){
            this.form_data.work_days = this.get_default_work_days()
        },
        addContactNumber(){
            this.form_data.contacts.push('')
        },
        removeContactNumber(index) {
            this.form_data.contacts.splice(index, 1)
        },
        confirmSaveBranch() {
            this.$vs.dialog({
                type: "confirm",
                color: "primary",
                title: `Confirm`,
                text: "You're going to save this Branch!",
                accept: () => this.saveBranch(),
            });
        },
        saveBranch() {
            const loading = this.$vs.loading();
            this.form_data.lat = this.form_data.location.lat;
            this.form_data.lng = this.form_data.location.lng;

            if(this.errors_bag.lat || this.errors_bag.lng){
                loading.close();
                this.$vs.notify({
                    title: "Error",
                    color: "danger",
                    text: "Invalid lat or long value",
                });
                return
            }


            this.form_data.work_days = this.form_data.work_days.map(day => {
                if(day.from.match(/\d{2}:\d{2}(:\d{2}| [AP]M)$/, "")){
                    day.from = day.from.replace(/(:\d{2}| [AP]M)$/, "");
                }

                if(day.to.match(/\d{2}:\d{2}(:\d{2}| [AP]M)$/, "")){
                    day.to = day.to.replace(/(:\d{2}| [AP]M)$/, "");
                }
                return day;
            })

            console.log('formData', this.form_data)

            this.$httpBranches
               .post("branches" + (this.item ? '/' + this.item.id : ''), this.form_data)
                .then((r) => {
                    this.$vs.loading.close();
                    console.log(r);
                    this.$vs.notify({
                        title: "Success",
                        color: "success",
                        text: "Branch is added successfully!",
                    });
                    this.goBack();
                })
                .catch(() => {
                    this.$vs.loading.close();
                    //console.log(e);
                    this.$vs.notify({
                        title: "Error",
                        color: "danger",
                        text: "Couldn't Save Branch!",
                    });
                });
        },
        goBack() {
            if(this.item){
                return this.$emit("OnBackClicked");
            }
            this.$router.push(`/branches/`);
        },
        getBrands() {
            this.$vs.loading();
            this.$httpCars
                .get("brands")
                .then((r) => {
                    this.$vs.loading.close();
                    this.mainBrands = r.data.data.brands;
                    this.brandsOptions = [];
                    for (let i = 0; i < this.mainBrands.length; i++) {
                        this.brandsOptions.push(this.mainBrands[i].id.toString());
                    }
                })
                .catch(() => {
                    this.$vs.loading.close();
                    //console.log(e);
                });
        },
        getTypeName(id) {
            console.log(id)
            return this.typeOptions[parseInt(id)] || '';
        },
        getServesName(id) {
            console.log('servesOptions',id, this.servesOptions)
            return this.servesOptions[parseInt(id)] || '';
        },
        getBrandName(id) {
            console.log(id)
            let founded =  this.mainBrands.find(brand => brand.id == id);
            return founded ? founded.name_en : '';
        },
        getTypeOptions() {
            return Object.keys(this.typeOptions)
        },
        getServesOptions() {
            return Object.keys(this.servesOptions)
        }

    },
    computed: {
        validBranch() {
            return (
                this.form_data.name_en !== "" &&
                this.form_data.name_ar !== "" &&
                this.form_data.address_en !== "" &&
                this.form_data.address_ar !== "" &&
                this.form_data.area_en && this.form_data.area_en.length >= 3 &&
                this.form_data.area_ar && this.form_data.area_ar.length >= 3  &&
                this.form_data.mobilenumber !== 0 &&
                // this.form_data.working_hours_from !== "" &&
                // this.form_data.working_hours_to !== "" &&
                this.form_data.types.length !== 0 &&
                this.form_data.brands.length !== 0 &&
                this.form_data.serve.length !== 0 &&
                !this.errors_bag.lat && !this.errors_bag.lng

            );
        },
    },
    watch: {
        item(val){
            console.log('watch item', val)
            if(val){
                this.form_data = this.item
            }
        },
        'form_data.location.lat' (val) {
            const regexLat = /^(-?[1-8]?\d(?:\.\d{1,18})?|90(?:\.0{1,18})?)$/;
            this.errors_bag.lat = !regexLat.test(val);
        },
        'form_data.location.lng'(val) {
            const regexLon = /^(-?(?:1[0-7]|[1-9])?\d(?:\.\d{1,18})?|180(?:\.0{1,18})?)$/;
            this.errors_bag.lng = !regexLon.test(val);
        }
    },
    mounted() {
        this.getBrands();
        if(this.item){
            this.form_data = this.item

            if(!this.form_data.contacts.length) {
                this.form_data.contacts = ['']
            }

            this.form_data.serve = (this.form_data.serve || []).map(item => item.toString())
            this.form_data.types = (this.form_data.types || []).map(item => item.toString())
            this.form_data.brands = (this.form_data.brands || []).map(item => item.toString())

            // console.log(this.form_data)

        }
    },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css" />

<style scoped lang="scss">
.clr {
    clear: both;
}

.body {
    background-color: #fff;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;

    .drop-down {
        display: flex;
        flex-flow: column;
        justify-content: flex-start;
        align-items: flex-start;
    }

    label {
        font-size: 1rem;
        padding: 5px;
    }

    select {
        padding: 10px;
        border-radius: 5px;
        width: 90%;
        background-color: #fff;
        border: 1px solid #e8e8e8;
    }

    .inputs {
        margin: 20px 0 20px 0;
        display: flex;
        flex-flow: row;
        justify-content: center;
        align-items: center;
    }
}

.input {
    width: 90%;
    margin-top: 30px;
}

.switch {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-top: 30px;
}

.checkboxes {
    margin: 10px;

    .checkbox {
        margin: 5px;
    }
}

.optionInput {
    margin-top: 30px;
    width: 50%;
    display: block;
    padding: 10px;
    border-radius: 5px;
    color: #00000090;
    margin-left: 10px;
}

h2 {
    margin: 30px;
}

.optionBtns {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}

h4 {
    padding: 20px;
}

.subtitle {
    margin-top: 50px;
    color: #ccc;
}

.input {
    width: 93%;
    margin-top: 10px;
}

label {
    font-size: 1rem;
    padding: 5px;
}

.multiselect {
    width: 93%;
}
</style>
