<template>
  <div>
    <vs-row>
      <vs-col vs-lg="6" vs-sm="12">
        <div class="drop-down">
          <label for="brands">Choose Brand</label>
          <select
            id="brands"
            v-model="brand_id"
            style="height: 40px"
            @change="$emit('onBrandChosen', brand_id)">
            <option value="0" selected disabled>Choose Brand</option>
            <option
              v-for="(brand, index) in brands"
              :value="brand.id"
              :key="index"
              :selected="brand_id == brand.id"
            >{{brand.name_en}}
            </option>
          </select>
        </div>
      </vs-col>
      <vs-col vs-lg="6" vs-sm="12">
        <div class="drop-down">
          <label for="models">Choose Model</label>
          <select
            id="models"
            v-model="model_id"
            @change="$emit('onModelChosen', model_id)"
            style="height: 40px"
          >
            <option value="0" selected disabled>Choose Model</option>
            <option
              v-for="(model, index) in modelsOfBrand"
              :value="model.id"
              :key="index"
              :selected="model_id ==  model.id"
            >{{model.name_en}}
            </option>
          </select>
        </div>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
  export default {
    name: "choose-model",
    props: {
      selectedModelID: {
        required: false,
        default: () => {
          return "0";
        }
      }
    },
    data() {
      return {
        brands: [],
        modelsOfBrand: [],
        model_id: "0",
        brand_id: "0"
      };
    },
    watch: {
      brand_id(v) {
        console.log({ brand_id: v });
        console.log({ models: this.models });
        this.modelsOfBrand = [];
        this.$emit("onModelChosen", this.model_id);
        for (let i = 0; i < this.models.length; i++) {
          if (v == this.models[i].brand.id) {
            this.modelsOfBrand.push(this.models[i]);
          }
        }
      }
    },
    methods: {
      getModels() {
        this.$vs.loading();
        this.$httpCars.get("models")
          .then(r => {
            let total = r.data.data.total;
            this.$httpCars
              .get(`models?per_page=${total}`)
              .then(r => {
                this.models = r.data.data.models;
                for (let i = 0; i < this.models.length; i++) {
                  if (this.selectedModelID != "0" &&
                    this.selectedModelID == this.models[i].id + "") {
                    this.brand_id = this.models[i].brand.id;
                    this.model_id = this.models[i].id;
                    break;
                  }
                }
                this.$vs.loading.close();
              })
              .catch(() => {
                this.$vs.loading.close();
              });
          }).catch(e => {
          console.log(e);
          this.$vs.loading.close();
        });


      },
      getBrands() {
        this.$vs.loading();
        this.$httpCars
          .get("brands")
          .then(r => {
            this.$vs.loading.close();
            this.brands = r.data.data.brands;
            this.getModels();
            //console.log(this.models);
          })
          .catch(() => {
            this.$vs.loading.close();
            //console.log(e);
          });
      }
    },
    mounted() {
      this.getBrands();
    }
  };
</script>

<style scoped lang="scss">
  .clr {
    clear: both;
  }

  .body {
    background-color: #fff;
    padding: 10px;
    margin: 10px;
    border-radius: 10px;

    .drop-down {
      display: flex;
      flex-flow: column;
      justify-content: flex-start;
      align-items: flex-start;
    }

    .inputs {
      margin: 20px 0 20px 0;
      display: flex;
      flex-flow: row;
      justify-content: center;
      align-items: center;
    }
  }

  label {
    font-size: 1rem;
    padding: 5px;
  }

  select {
    padding: 10px;
    border-radius: 5px;
    width: 90%;
    background-color: #fff;
    border: 1px solid #00000030;
  }

  .input {
    width: 100%;
    margin-top: 30px;
  }

  .switches {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    align-items: center;
    margin: 20px;
  }

  .optionInput {
    margin-top: 30px;
    width: 50%;
    display: block;
    padding: 10px;
    border-radius: 5px;
    color: #00000090;
    margin-left: 10px;
  }

  h2 {
    margin: 30px;
  }

  .optionBtns {
    margin-top: 20px;
    margin-bottom: 20px;
    margin-right: 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }

  h4 {
    padding: 20px;
  }

  .subtitle {
    margin-top: 50px;
    color: #ccc;
  }
</style>
