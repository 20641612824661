<template>
  <div class="vs-component vs-con-input-label vs-input mt-5 w-full vs-input-primary">
    <label class="vs-input--label" for="text-area">{{label}}</label>
    <textarea
      v-model="inputVal"
      id="text-area"
      class="w-full vs-inputx vs-input--input normal"
      :name="name"
      :rows="rows"
    ></textarea>
  </div>
</template>

<script>
    export default {
        name: "text-area",
        props: ["value", "label", "rows", "name"],
        computed: {
            inputVal: {
                get() {
                    return this.value;
                },
                set(val) {
                    this.$emit('input', val);
                }
            }
        }
    }
</script>

<style scoped>

</style>
