import { render, staticRenderFns } from "./text-area.vue?vue&type=template&id=5096b93c&scoped=true&"
import script from "./text-area.vue?vue&type=script&lang=js&"
export * from "./text-area.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5096b93c",
  null
  
)

export default component.exports